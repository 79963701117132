/*
===================================
MACBOOK
===================================
*/

@media screen and (min-width: 2561px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 16%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 50%;
        background-color: whitesmoke;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        z-index: 999;
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }   
}

/*
===================================
iMAC
===================================
*/

@media screen and (min-width: 1992px) and (max-width: 2560px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 16%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 50%;
        background-color: whitesmoke;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        z-index: 999;
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }   
}

/*
===================================
DESKTOP LARGE
===================================
*/

@media screen and (min-width: 1600px) and (max-width: 1991px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 16%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 50%;
        background-color: whitesmoke;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        z-index: 999;
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }   
}

/*
===================================
DESKTOP
===================================
*/

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 16%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 50%;
        background-color: whitesmoke;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        z-index: 999;
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }       
}

/*
===================================
WEB LARGE
===================================
*/

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 11%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 50%;
        background-color: whitesmoke;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        z-index: 999;
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }       
}

/*
===================================
WEB SMALLER
===================================
*/

@media screen and (min-width: 768px) and (max-width: 1023px) {

    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 25%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 10%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        z-index: 999;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
}

/*
===================================
TABLET
===================================
*/

@media screen and (min-width: 480px) and (max-width: 767px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 25%;
        right: 38%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 7%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 3vw;
        padding: 3% 0% 0% 0%;
        z-index: 999;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 2vw;
        padding: 0% 0% 15% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
}

/*
===================================
MOBILE
===================================
*/

@media screen and (max-width: 479px) {
    .news-page-content-wrapper {
        background-color: whitesmoke;
    }
    
    .news-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
    }
    
    .news-page-header-img {
        width: 100%;
    }
    
    .news-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 9vw;
        bottom: 20%;
        right: 34%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .news-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 5%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .news-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .news-page-coming-soon {
        text-align: center;
        font-size: 7vw;
        padding: 27% 0% 10% 0%;
        z-index: 999;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .news-page-content-para {
        text-align: center;
        font-size: 3.7vw;
        padding: 0% 0% 56% 0%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }    
}