
.body {
  background-color: rgb(22, 6, 80,0.75);  
}

/*
===================================
MACBOOK
===================================
*/

@media screen and (min-width: 2561px) {
  /*Loading Screen*/
  
  .mobile-menu-icon {
  display: none;
  }
  
  .loading {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    z-index: 999;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-image {
    width: 10%;
    z-index: 999;
    animation: nubLoad ease-in 1s;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubLoad{
    0% {
      width: 10%;
      opacity: 0;
    }
    50% {
      width: 12%;
      opacity: 1;
    }
    100%{
      width: 10%;
      opacity: 0;
    }
  }
  
  /*Nub on Website*/
  .nub {
    z-index: 999;
    width: 12%;
    position: fixed;
    bottom: 0%;
    right: 2%;
    animation: nubMovement ease-in 15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubMovement{
    0% {
      transform: translateY(200px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  /*Navbar*/
  .navbar {
    background-color: rgb(22, 6, 80, .6);
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0.5% 0.5% 0.5% .5%;
  }
  
  .nav-image {
    width: 6%;
    padding: 0.2% 3% 0.2% 0.2%;
    margin-left: 1%;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-item {
    padding: 0% 6% 0% 0%;
    color: whitesmoke;
    font-weight: 645;
    font-size: 1.3vw;
    text-decoration: none;
    transition: color .25s ease-out 25ms;
  }
  
  
  .nav-item:hover {
    color: rgb(145, 140, 216);
    cursor: pointer;
  }
  
  .apply-button {
    width: 10%;
    font-size: 1.3vw;
    padding: .5%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    margin-left: auto;
    margin-right: 3%;
  }
  
  .apply-link {
    color: whitesmoke; 
    text-decoration: none; 
  }
  
  .apply-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.35vw;
    cursor: pointer;
  }
  
  .visual {
    animation: scrollAnimation ease-out 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes scrollAnimation {
    0% {
      color: whitesmoke;
    }
    100% {
      color: rgb(145, 140, 216);
      }
  }
  
  /* Footer */
  
  .footer-wrapper {
    display: flex;
    background-color: rgb(22, 6, 80,0.80);
    align-items: center;
    width: 100%;
  }
  
  .footer-logo {
    width: 6.5vw;
    padding: .05%;
    margin-left: 2%;
    margin-right: 15%;
  }
  
  .footer-socials {
    display: flex;
    align-items: center;
    flex-direction: row; 
    justify-content: center;
    margin: 1%;
    padding: 0.05%;
    margin-right: 4%;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-around;
    padding: 2%;
  }
  
  .footer-icon {
    width: 3vw;
    display: inline;
    background-color: rgb(12, 3, 43);
    border-radius: 500px;
    transition: background-color .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  }
  
  .footer-icon:hover {
    background-color: rgb(30, 10, 104); 
  }
  
  .footer-text {
    color: whitesmoke;
    padding: .5%;
    font-size: 1.2vw;
    margin: auto;
  }
  
  /* CSS Animation to load content while scrolling */
  
  .reveal{
    position: relative;
    transform: translateY(30px);
    opacity: 0;
    transition: 1s all linear;
  }
  
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }    
  }

/*
===================================
iMAC
===================================
*/

@media screen and (min-width: 1992px) and (max-width: 2560px) {
  /*Loading Screen*/
  
  .mobile-menu-icon {
  display: none;
  }
  
  .loading {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    z-index: 999;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-image {
    width: 10%;
    z-index: 999;
    animation: nubLoad ease-in 1s;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubLoad{
    0% {
      width: 10%;
      opacity: 0;
    }
    50% {
      width: 12%;
      opacity: 1;
    }
    100%{
      width: 10%;
      opacity: 0;
    }
  }
  
  /*Nub on Website*/
  .nub {
    z-index: 999;
    width: 12%;
    position: fixed;
    bottom: 0%;
    right: 2%;
    animation: nubMovement ease-in 15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubMovement{
    0% {
      transform: translateY(200px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  /*Navbar*/
  .navbar {
    background-color: rgb(22, 6, 80, .6);
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0.5% 0.5% 0.5% .5%;
  }
  
  .nav-image {
    width: 6%;
    padding: 0.2% 3% 0.2% 0.2%;
    margin-left: 1%;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-item {
    padding: 0% 6% 0% 0%;
    color: whitesmoke;
    font-weight: 645;
    font-size: 1.3vw;
    text-decoration: none;
    transition: color .25s ease-out 25ms;
  }
  
  
  .nav-item:hover {
    color: rgb(145, 140, 216);
    cursor: pointer;
  }
  
  .apply-button {
    width: 10%;
    font-size: 1.3vw;
    padding: .5%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    margin-left: auto;
    margin-right: 3%;
  }
  
  .apply-link {
    color: whitesmoke; 
    text-decoration: none; 
  }
  
  .apply-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.35vw;
    cursor: pointer;
  }
  
  .visual {
    animation: scrollAnimation ease-out 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes scrollAnimation {
    0% {
      color: whitesmoke;
    }
    100% {
      color: rgb(145, 140, 216);
      }
  }
  
  /* Footer */
  
  .footer-wrapper {
    display: flex;
    background-color: rgb(22, 6, 80,0.80);
    align-items: center;
    width: 100%;
  }
  
  .footer-logo {
    width: 6.5vw;
    padding: .05%;
    margin-left: 2%;
    margin-right: 15%;
  }
  
  .footer-socials {
    display: flex;
    align-items: center;
    flex-direction: row; 
    justify-content: center;
    margin: 1%;
    padding: 0.05%;
    margin-right: 4%;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-around;
    padding: 2%;
  }
  
  .footer-icon {
    width: 3vw;
    display: inline;
    background-color: rgb(12, 3, 43);
    border-radius: 500px;
    transition: background-color .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  }
  
  .footer-icon:hover {
    background-color: rgb(30, 10, 104); 
  }
  
  .footer-text {
    color: whitesmoke;
    padding: .5%;
    font-size: 1.2vw;
    margin: auto;
  }
  
  /* CSS Animation to load content while scrolling */
  
  .reveal{
    position: relative;
    transform: translateY(30px);
    opacity: 0;
    transition: 1s all linear;
  }
  
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }    
  }

/*
===================================
DESKTOP LARGE
===================================
*/

@media screen and (min-width: 1600px) and (max-width: 1991px) {
/*Loading Screen*/

.mobile-menu-icon {
display: none;
}

.loading {
  background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  width: 10%;
  z-index: 999;
  animation: nubLoad ease-in 1s;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes nubLoad{
  0% {
    width: 10%;
    opacity: 0;
  }
  50% {
    width: 12%;
    opacity: 1;
  }
  100%{
    width: 10%;
    opacity: 0;
  }
}

/*Nub on Website*/
.nub {
  z-index: 999;
  width: 12%;
  position: fixed;
  bottom: 0%;
  right: 2%;
  animation: nubMovement ease-in 15s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes nubMovement{
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*Navbar*/
.navbar {
  background-color: rgb(22, 6, 80, .6);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0.5% 0.5% 0.5% .5%;
}

.nav-image {
  width: 6%;
  padding: 0.2% 2% 0.2% 0.2%;
  margin-left: 1%;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.nav-item {
  padding: 0% 6% 0% 0%;
  color: whitesmoke;
  font-weight: 645;
  font-size: 1.3vw;
  text-decoration: none;
  transition: color .25s ease-out 25ms;
}


.nav-item:hover {
  color: rgb(145, 140, 216);
  cursor: pointer;
}

.apply-button {
  width: 10%;
  font-size: 1.3vw;
  padding: .5%;
  border: none;
  color: whitesmoke;
  background-color: rgb(145, 140, 216);
  border-radius: 500px;
  font-weight: 550;
  text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
  transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  border: 3px solid rgba(191, 186, 219, 0.904);
  margin-left: auto;
  margin-right: 3%;
}

.apply-link {
  color: whitesmoke; 
  text-decoration: none; 
}

.apply-button:hover{
  background-color: rgba(173, 165, 223);
  font-size: 1.35vw;
  cursor: pointer;
}

.visual {
  animation: scrollAnimation ease-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scrollAnimation {
  0% {
    color: whitesmoke;
  }
  100% {
    color: rgb(145, 140, 216);
    }
}

/* Footer */

.footer-wrapper {
  display: flex;
  background-color: rgb(22, 6, 80,0.80);
  align-items: center;
  width: 100%;
}

.footer-logo {
  width: 6.5vw;
  padding: .05%;
  margin-left: 2%;
  margin-right: 15%;
}

.footer-socials {
  display: flex;
  align-items: center;
  flex-direction: row; 
  justify-content: center;
  margin: 1%;
  padding: 0.05%;
  margin-right: 4%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  padding: 2%;
}

.footer-icon {
  width: 3vw;
  display: inline;
  background-color: rgb(12, 3, 43);
  border-radius: 500px;
  transition: background-color .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.footer-icon:hover {
  background-color: rgb(30, 10, 104); 
}

.footer-text {
  color: whitesmoke;
  padding: .5%;
  font-size: 1.2vw;
  margin: auto;
}

/* CSS Animation to load content while scrolling */

.reveal{
  position: relative;
  transform: translateY(30px);
  opacity: 0;
  transition: 1s all linear;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}    
}

/*
===================================
DESKTOP
===================================
*/

@media screen and (min-width: 1200px) and (max-width: 1599px) {
/*Loading Screen*/

.mobile-menu-icon {
  display: none;
  }

.loading {
  background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  width: 10%;
  z-index: 999;
  animation: nubLoad ease-in 1s;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes nubLoad{
  0% {
    width: 10%;
    opacity: 0;
  }
  50% {
    width: 12%;
    opacity: 1;
  }
  100%{
    width: 10%;
    opacity: 0;
  }
}

/*Nub on Website*/
.nub {
  z-index: 999;
  width: 12%;
  position: fixed;
  bottom: 0%;
  right: 2%;
  animation: nubMovement ease-in 15s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes nubMovement{
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*Navbar*/
.navbar {
  background-color: rgb(22, 6, 80, .6);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0.5% 0.5% 0.5% .5%;
}

.nav-image {
  width: 6%;
  padding: 0.2% 2% 0.2% 0.2%;
  margin-left: 1%;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.nav-item {
  padding: 0% 6% 0% 0%;
  color: whitesmoke;
  font-weight: 645;
  font-size: 1.3vw;
  text-decoration: none;
  transition: color .25s ease-out 25ms;
}


.nav-item:hover {
  color: rgb(145, 140, 216);
  cursor: pointer;
}

.apply-button {
  width: 10%;
  font-size: 1.3vw;
  padding: .5%;
  border: none;
  color: whitesmoke;
  background-color: rgb(145, 140, 216);
  border-radius: 500px;
  font-weight: 550;
  text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
  transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  border: 3px solid rgba(191, 186, 219, 0.904);
  margin-left: auto;
  margin-right: 3%;
}

.apply-link {
  color: whitesmoke; 
  text-decoration: none; 
}

.apply-button:hover{
  background-color: rgba(173, 165, 223);
  font-size: 1.35vw;
  cursor: pointer;
}

.visual {
  animation: scrollAnimation ease-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scrollAnimation {
  0% {
    color: whitesmoke;
  }
  100% {
    color: rgb(145, 140, 216);
    }
}

/* Footer */

.footer-wrapper {
  display: flex;
  background-color: rgb(22, 6, 80,0.80);
  align-items: center;
  width: 100%;
}

.footer-logo {
  width: 6.5vw;
  padding: .05%;
  margin-left: 2%;
  margin-right: 15%;
}

.footer-socials {
  display: flex;
  align-items: center;
  flex-direction: row; 
  justify-content: center;
  margin: 1%;
  padding: 0.05%;
  margin-right: 4%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  padding: 2%;
}

.footer-icon {
  width: 3vw;
  display: inline;
  background-color: rgb(12, 3, 43);
  border-radius: 500px;
  transition: background-color .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.footer-icon:hover {
  background-color: rgb(30, 10, 104); 
}

.footer-text {
  color: whitesmoke;
  padding: .5%;
  font-size: 1.2vw;
  margin: auto;
}

/* CSS Animation to load content while scrolling */

.reveal{
  position: relative;
  transform: translateY(30px);
  opacity: 0;
  transition: 1s all linear;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}        
}

/*
===================================
WEB LARGE
===================================
*/

@media screen and (min-width: 1024px) and (max-width: 1199px) {
/*Loading Screen*/

.mobile-menu-icon {
  display: none;
  }

.loading {
  background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  width: 10%;
  z-index: 999;
  animation: nubLoad ease-in 1s;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes nubLoad{
  0% {
    width: 10%;
    opacity: 0;
  }
  50% {
    width: 12%;
    opacity: 1;
  }
  100%{
    width: 10%;
    opacity: 0;
  }
}

/*Nub on Website*/
.nub {
  z-index: 999;
  width: 12%;
  position: fixed;
  bottom: 0%;
  right: 2%;
  animation: nubMovement ease-in 15s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes nubMovement{
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*Navbar*/
.navbar {
  background-color: rgb(22, 6, 80, .6);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0.5% 0.5% 0.5% .5%;
}

.nav-image {
  width: 8%;
  padding: 0.2% 2% 0.2% 0.2%;
  margin-left: 1%;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.nav-item {
  padding: 0% 6% 0% 0%;
  color: whitesmoke;
  font-weight: 645;
  font-size: 1.8vw;
  text-decoration: none;
  transition: color .25s ease-out 25ms;
}


.nav-item:hover {
  color: rgb(145, 140, 216);
  cursor: pointer;
}

.apply-button {
  width: 13%;
  font-size: 1.8vw;
  padding: .5%;
  border: none;
  color: whitesmoke;
  background-color: rgb(145, 140, 216);
  border-radius: 500px;
  font-weight: 550;
  text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
  transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  border: 2px solid rgba(191, 186, 219, 0.904);
  margin-left: auto;
  margin-right: 3%;
}

.apply-link {
  color: whitesmoke; 
  text-decoration: none; 
}

.apply-button:hover{
  background-color: rgba(173, 165, 223);
  font-size: 1.85vw;
  cursor: pointer;
}

.visual {
  animation: scrollAnimation ease-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scrollAnimation {
  0% {
    color: whitesmoke;
  }
  100% {
    color: rgb(145, 140, 216);
    }
}

/* Footer */

.footer-wrapper {
  display: flex;
  background-color: rgb(22, 6, 80,0.80);
  align-items: center;
  width: 100%;
}

.footer-logo {
  width: 7.5vw;
  padding: .05%;
  margin-left: 2%;
  margin-right: 14%;
}

.footer-socials {
  display: flex;
  align-items: center;
  flex-direction: row; 
  justify-content: center;
  margin: 1%;
  padding: 0.05%;
  margin-right: 4%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  padding: 2%;
}

.footer-icon {
  width: 3.5vw;
  display: inline;
  background-color: rgb(12, 3, 43);
  border-radius: 500px;
  transition: background-color .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.footer-icon:hover {
  background-color: rgb(30, 10, 104); 
}

.footer-text {
  color: whitesmoke;
  padding: .5%;
  font-size: 1.2vw;
  margin: auto;
}

/* CSS Animation to load content while scrolling */

.reveal{
  position: relative;
  transform: translateY(30px);
  opacity: 0;
  transition: 1s all linear;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}        
}

/*
===================================
WEB SMALLER
===================================
*/

@media screen and (min-width: 769px) and (max-width: 1023px) {
  /*Loading Screen*/

  .mobile-menu-icon {
    display: none;
    }

.loading {
  background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  width: 18%;
  z-index: 999;
  animation: nubLoad ease-in 1s;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes nubLoad{
  0% {
    width: 18%;
    opacity: 0;
  }
  50% {
    width: 20%;
    opacity: 1;
  }
  100%{
    width: 18%;
    opacity: 0;
  }
}

/*Nub on Website*/
.nub {
  z-index: 999;
  width: 20%;
  position: fixed;
  bottom: 0%;
  right: 2%;
  animation: nubMovement ease-in 15s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes nubMovement{
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*Navbar*/
.navbar {
  background-color: rgb(22, 6, 80, .6);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0.5% 0.5% 0.5% .5%;
}

.nav-image {
  width: 8%;
  padding: 0.2% 2% 0.2% 0.2%;
  margin-left: 1%;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.nav-item {
  padding: 0% 6% 0% 0%;
  color: whitesmoke;
  font-weight: 645;
  font-size: 1.5vw;
  text-decoration: none;
  transition: color .25s ease-out 25ms;
}


.nav-item:hover {
  color: rgb(145, 140, 216);
  cursor: pointer;
}

.apply-button {
  width: 10%;
  font-size: 1.3vw;
  padding: .5%;
  border: none;
  color: whitesmoke;
  background-color: rgb(145, 140, 216);
  border-radius: 500px;
  font-weight: 550;
  text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
  transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  border: 2px solid rgba(191, 186, 219, 0.904);
  margin-left: auto;
  margin-right: 3%;
}

.apply-link {
  color: whitesmoke; 
  text-decoration: none; 
}

.apply-button:hover{
  background-color: rgba(173, 165, 223);
  font-size: 1.35vw;
  cursor: pointer;
}

.visual {
  animation: scrollAnimation ease-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scrollAnimation {
  0% {
    color: whitesmoke;
  }
  100% {
    color: rgb(145, 140, 216);
    }
}


/* Footer */

.footer-wrapper {
  display: flex;
  background-color: rgb(22, 6, 80,0.80);
  align-items: center;
  width: 100%;
}

.footer-logo {
  width: 8vw;
  padding: .05%;
  margin-left: 2%;
  margin-right: 15%;
}

.footer-socials {
  display: flex;
  align-items: center;
  flex-direction: row; 
  justify-content: center;
  margin: 1%;
  margin: 1%;
  padding: 0.05%;
  margin-right: 4%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  padding: 2%;
}

.footer-icon {
  width: 3.7vw;
  display: inline;
  background-color: rgb(12, 3, 43);
  border-radius: 500px;
  transition: background-color .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.footer-icon:hover {
  background-color: rgb(30, 10, 104); 
}

.footer-text {
  color: whitesmoke;
  padding: .5%;
  font-size: 1.2vw;
  margin: auto;
}

/* CSS Animation to load content while scrolling */

.reveal{
  position: relative;
  transform: translateX(30px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateX(0);
  opacity: 1;
}
  }

/*
===================================
TABLET
===================================
*/

@media screen and (min-width: 480px) and (max-width: 768px) {
  /*Loading Screen*/

  .mobile-menu-icon {
    background-color: rgb(22, 6, 80, .0);
    border: 0;
    color: whitesmoke;
    padding-top: 5px;
    padding-bottom: 1px;
    z-index: 10;
    font-size: 5vw;
    align-self: center;
  }

  .loading {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    z-index: 999;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-image {
    width: 18%;
    z-index: 999;
    animation: nubLoad ease-in 1s;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubLoad{
    0% {
      width: 18%;
      opacity: 0;
    }
    50% {
      width: 20%;
      opacity: 1;
    }
    100%{
      width: 18%;
      opacity: 0;
    }
  }
  
  /*Nub on Website*/
  .nub {
    z-index: 999;
    width: 20%;
    position: fixed;
    bottom: 0%;
    right: 2%;
    animation: nubMovement ease-in 15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes nubMovement{
    0% {
      transform: translateY(200px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  /*Navbar*/
  .navbar {
    background-color: rgb(22, 6, 80, .6);
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 0.5% 0.5% 0.5% .5%;
  }
  
  .nav-image {
    width: 8%;
    padding: 0.2% 2% 0.2% 0.2%;
    margin-left: 1%;
  }
  
  .nav-links {
    list-style: none;
    display: none;
    justify-content: space-around;
  }

  .nav-links-mobile {
    position: absolute;
    top: 40px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh);
    background-color: rgb(22, 6, 80);
    z-index: 100;
    list-style: none;
  }
  
  .nav-item {
    margin: 10px;
    color: whitesmoke;
    width: 100%;
    padding-right: 70px;
    padding-top: 10px;
    font-weight: 645;
    text-align: right;
    text-decoration: none;
    font-size: 5vw;
  }
  
  .nav-item:hover {
    color: rgb(145, 140, 216);
    cursor: pointer;
  }
  
  .apply-button {
    width: 10%;
    font-size: 1.3vw;
    padding: .5%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
    margin-left: auto;
    margin-right: 1%;
  }
  
  .apply-link {
    color: whitesmoke; 
    text-decoration: none; 
  }
  
  .apply-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.35vw;
    cursor: pointer;
  }
  
  .visual {
    animation: scrollAnimation ease-out 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes scrollAnimation {
    0% {
      color: whitesmoke;
    }
    100% {
      color: rgb(145, 140, 216);
      }
  }
  
  
  /* Footer */
  
  .footer-wrapper {
    display: flex;
    background-color: rgb(22, 6, 80,0.80);
    align-items: center;
    width: 100%;
  }
  
  .footer-logo {
    width: 8vw;
    padding: .05%;
    margin-left: 2%;
    margin-right: 15%;
  }
  
  .footer-socials {
    display: flex;
    align-items: center;
    flex-direction: row; 
    justify-content: center;
    margin: 1%;
    margin: 1%;
    padding: 0.05%;
    margin-right: 4%;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-around;
    padding: 2%;
  }
  
  .footer-icon {
    width: 3.7vw;
    display: inline;
    background-color: rgb(12, 3, 43);
    border-radius: 500px;
    transition: background-color .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  }
  
  .footer-icon:hover {
    background-color: rgb(30, 10, 104); 
  }
  
  .footer-text {
    color: whitesmoke;
    padding: .5%;
    font-size: 1.2vw;
    margin: auto;
  }
  
  /* CSS Animation to load content while scrolling */
  
  .reveal{
    position: relative;
    transform: translateX(30px);
    opacity: 0;
    transition: 1s all ease;
  }
  
  .reveal.active{
    transform: translateX(0);
    opacity: 1;
  }
}

/*
===================================
MOBILE
===================================
*/

@media screen and (max-width: 479px) {

  .loading {
      background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
      z-index: 999;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .loading-image {
      z-index: 999;
      animation: nubLoad ease-in 1s;
      animation-iteration-count: 3;
      animation-fill-mode: forwards;
  }
  
  @keyframes nubLoad{
      0% {
      width: 30%;
      opacity: 0;
      }
      50% {
      width: 32%;
      opacity: 1;
      }
      100%{
      width: 30%;
      opacity: 0;
      }
  }
  
  /*Nub on Website*/
  .nub {
      z-index: 999;
      width: 30%;
      position: fixed;
      bottom: 0%;
      right: 2%;
      animation: nubMovement ease-in 15s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
  }
  
  @keyframes nubMovement{
      0% {
      transform: translateY(200px);
      }
      100% {
      transform: translateY(0px);
      }
  }
  
/*Navbar*/
.navbar {
  background-color: rgb(22, 6, 80, .6);
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0.5% 0.5% 0.5% .5%;
}

.nav-image {
  width: 12%;
  padding: 0.2% 2% 0.2% 0.2%;
  margin-left: 1%;
}

.nav-links {
  display: none;
}

.mobile-menu-icon {
  background-color: rgb(22, 6, 80, .0);
  border: 0;
  color: whitesmoke;
  padding-top: 5px;
  padding-bottom: 1px;
  z-index: 10;
  font-size: 7vw;
  align-self: center;
}

.nav-links-mobile {
  position: absolute;
  top: 22px;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: calc(100vh);
  background-color: rgb(22, 6, 80);
  z-index: 100;
  list-style: none;
}

.nav-item {
  margin: 10px;
  color: whitesmoke;
  width: 100%;
  padding-right: 70px;
  padding-top: 10px;
  font-weight: 645;
  text-align: right;
  text-decoration: none;
  font-size: 9vw;
}

.nav-item:hover {
  color: rgb(145, 140, 216);
  cursor: pointer;
}

.apply-button {
  width: 16%;
  font-size: 2vw;
  padding: .5%;
  border: none;
  color: whitesmoke;
  background-color: rgb(145, 140, 216);
  border-radius: 500px;
  font-weight: 550;
  text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
  transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
  border: 2px solid rgba(191, 186, 219, 0.904);
  margin-left: auto;
  margin-right: 1%;
}

.apply-link {
  color: whitesmoke; 
  text-decoration: none; 
}

.apply-button:hover{
  background-color: rgba(173, 165, 223);
  font-size: 2.1vw;
  cursor: pointer;
}

.visual {
  animation: scrollAnimation ease-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scrollAnimation {
  0% {
    color: whitesmoke;
  }
  100% {
    color: rgb(145, 140, 216);
    }
}


/* Footer */

.footer-wrapper {
  display: flex;
  background-color: rgb(22, 6, 80,0.80);
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.footer-logo {
  width: 21vw;
  padding: 3%;
}

.footer-socials {
  display: flex;
  align-items: center;
  flex-direction: row; 
  justify-content: center;
  margin: 1%;
  padding: 0.05%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  padding: 3%;
}

.footer-icon {
  width: 10vw;
  display: inline;
  background-color: rgb(12, 3, 43);
  border-radius: 500px;
  transition: background-color .25s ease-out 25ms;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.footer-icon:hover {
  background-color: rgb(30, 10, 104); 
}

.footer-text {
  color: whitesmoke;
  text-align: center;
  font-size: 3.2vw;
  padding: 3%;
}
  
    /* CSS Animation to load content while scrolling */
  
  .reveal{
      position: relative;
      opacity: 0;
      transition: 1s all ease;
  }
  
  .reveal.active{
      opacity: 1;
  }
  }