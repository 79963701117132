/*
===================================
MACBOOK
===================================
*/

@media screen and (min-width: 2561px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1-mac.png);
        }
        50% {
            background-image: url(../images/char-bg-2-mac.png);
        }
        100% {
            background-image: url(../images/char-bg-1-mac.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 60%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 9%;
    }
    
    .character-slide-header {
        font-size: 6vw;
        height: 2%;
        padding: 14.5% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2.5vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 70%;
        padding: 2% 0% 3% 0%;
        margin-bottom: 3%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.55vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 40%;
        margin-bottom: 10%;
        height: max-content;
        width: 120%;
        padding: 3% 5% 3% 5%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 45%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 45%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 67.5%;
        padding-top: 1%;
        width: 100%;
        height: 8.5vw;
    }
    
    .content-spacer {
        padding: 3%;
    }
    
    
    .swiper-button-next {
        right: 0;
        height: .5%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: .5%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.2vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }   
}

/*
===================================
iMAC
===================================
*/

@media screen and (min-width: 1992px) and (max-width: 2560px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1-mac.png);
        }
        50% {
            background-image: url(../images/char-bg-2-mac.png);
        }
        100% {
            background-image: url(../images/char-bg-1-mac.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 60%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 9%;
    }
    
    .character-slide-header {
        font-size: 6vw;
        height: 2%;
        padding: 14.5% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2.5vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 70%;
        padding: 2% 0% 3% 0%;
        margin-bottom: 3%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.55vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 40%;
        margin-bottom: 10%;
        height: max-content;
        width: 120%;
        padding: 3% 5% 3% 5%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 45%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 45%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 75%;
        padding-top: 1%;
        width: 100%;
        height: 8.5vw;
    }
    
    .content-spacer {
        padding: 3%;
    }
    
    
    .swiper-button-next {
        right: 0;
        height: .5%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: .5%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.2vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }    
}

/*
===================================
DESKTOP LARGE
===================================
*/

@media screen and (min-width: 1600px) and (max-width: 1991px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1.png);
        }
        50% {
            background-image: url(../images/char-bg-2.png);
        }
        100% {
            background-image: url(../images/char-bg-1.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 65.5%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 15%;
    }
    
    .character-slide-header {
        font-size: 4.5vw;
        height: 2%;
        padding: 22% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 100%;
        padding: 2% 0% 2% 0%;
        margin-bottom: 5%;
        margin-left: 20%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.3vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 30%;
        width: 185%;
        padding: 5% 5% 5% 5%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 50%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 50%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 76.6%;
        padding-top: 1%;
        width: 100%;
        height: 10.7vw;
    }
    
    .content-spacer {
        padding: 3%;
    }
    
    
    .swiper-button-next {
        right: 0;
        height: .5%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: .5%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }    
}

/*
===================================
DESKTOP
===================================
*/

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1.png);
        }
        50% {
            background-image: url(../images/char-bg-2.png);
        }
        100% {
            background-image: url(../images/char-bg-1.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 65.5%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 15%;
    }
    
    .character-slide-header {
        font-size: 5.5vw;
        height: 2%;
        padding: 20% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 100%;
        padding: 2% 0% 2% 0%;
        margin-bottom: 5%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.3vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 36%;
        height: max-content;
        width: 200%;
        padding: 5% 0% 5% 5%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 50%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 50%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 70%;
        padding-top: 1%;
        width: 100%;
        height: 10vw;
    }
    
    .swiper-button-next {
        right: 0;
        height: .5%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: .5%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }      
}

/*
===================================
WEB LARGE
===================================
*/

@media screen and (min-width: 1025px) and (max-width: 1199px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1.png);
        }
        50% {
            background-image: url(../images/char-bg-2.png);
        }
        100% {
            background-image: url(../images/char-bg-1.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 77%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 13%;
    }
    
    .character-slide-header {
        font-size: 5.5vw;
        height: 2%;
        padding: 70% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 200%;
        padding: 2% 0% 2% 0%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.5vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 50%;
        height: max-content;
        width: 375%;
        padding: 15%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 50%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 50%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 73%;
        padding-top: 1%;
        width: 100%;
        height: 10.6vw;
    }
    
    .swiper-button-next {
        right: 0;
        height: .5%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: .5%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }      
}

/*
===================================
WEB SMALLER
===================================
*/

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/char-bg-1.png);
        }
        50% {
            background-image: url(../images/char-bg-2.png);
        }
        100% {
            background-image: url(../images/char-bg-1.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 65.5%;
        z-index: 4;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: 13%;
    }
    
    .character-slide-header {
        font-size: 5.5vw;
        height: 2%;
        padding: 35% 0% 0% 10%;
        color: whitesmoke;
        z-index: 5;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    }
    
    .character-slide-name {
        font-size: 2vw;
        background-color: rgba( 245, 245, 245, .5);
        height: 6%;
        width: 120%;
        margin-bottom: 2%;
        padding: 2% 0% 2% 0%;
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .character-slide-text {
        font-size: 1.5vw;
        background-color: rgba( 245, 245, 245, .5);
        margin-left: 50%;
        margin-bottom: 30%;
        height: max-content;
        width: 200%;
        padding: 5% 0% 5% 5%;
        z-index: 5;
        text-align: left;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    
    .character-slides-thumbs {
        width: 52%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 48%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 1vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 70.5%;
        padding-top: 1%;
        width: 100%;
        height: 10vw;
    }
    
    .content-spacer {
        padding: 3%;
    }
    
    
    .swiper-button-next {
        right: 0;
        height: 30%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: 30%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 1.3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }
}

/*
===================================
TABLET
===================================
*/

@media screen and (min-width: 480px) and (max-width: 768px) {
    .character-page-wrapper {
        height: max-content;
    }
    
    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/mobile/char-bg-1_mobile.png);
        }
        50% {
            background-image: url(../images/mobile/char-bg-2_mobile.png);
        }
        100% {
            background-image: url(../images/mobile/char-bg-1_mobile.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        align-content: flex-start;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 147%;
        z-index: 4;
        padding: 0% 32% 0% 0%;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        margin: auto;
    }
    
    .character-slide-header {
        font-size: 8vw;
        width: 100%;
        height: max-content;
        padding: 2% 200% 2% 200%;
        color: whitesmoke;
        background-color: rgba( 245, 245, 245, .5);
        z-index: 5;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .character-slide-name {
        font-size: 8vw;
        width: 100%;
        height: max-content;        
        padding: 2% 200% 2% 200%;
        background-color: rgba(245, 245, 245, 0.404);
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .character-slide-text {
        background-color: rgba( 245, 245, 245, .5);
        font-size: 4vw;
        width: 100%;
        height: max-content;
        padding: 5% 200% 5% 200%;
        z-index: 5;
        text-align: center;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
        visibility: hidden;
    }
    
    
    .character-slides-thumbs {
        width: 80%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 50%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 2vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background-color: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0%;
        top: 71%;
        padding-top: 1%;
        width: 100%;
        height: 17.5vw;
    }
    
    
    .swiper-button-next {
        right: 0;
        height: 30%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: 30%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
        visibility: hidden;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 2.3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }
}

/*
===================================
MOBILE
===================================
*/

@media screen and (max-width: 479px) {

    .character-page-wrapper {
        position: relative;
        width: 100%;
        height: max-content;
        overflow: hidden;
    }

    .character-slideshow-wrapper {
        animation: change 12s infinite ease-in-out;
        background-size: auto 100vh;
    }
    
    @keyframes change {
        0% {
            background-image: url(../images/mobile/char-bg-1_mobile.png);
        }
        50% {
            background-image: url(../images/mobile/char-bg-2_mobile.png);
        }
        100% {
            background-image: url(../images/mobile/char-bg-1_mobile.png);
        }
    }
    
    .character-slide-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        align-content: flex-start;
    }
    
    .character-slideshow {
        position: relative;
        width: 100%;
        height: 100%;
    }
    
    .character-slide-image {
        width: 147%;
        z-index: 4;
        padding: 0% 32% 0% 0%;
    /*   animation: characterAnimation ease-in-out 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; */
    }
    
    /* @keyframes characterAnimation {
        0%{
        transform: translateX(100px);
        }
    
        100%{
        transform: translateX(0px);
        }
    }
    */
    .character-slide-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        margin: auto;
    }
    
    .character-slide-header {
        font-size: 10vw;
        width: 100%;
        height: max-content;
        padding: 2% 200% 2% 200%;
        color: whitesmoke;
        background-color: rgba( 245, 245, 245, .5);
        z-index: 5;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .character-slide-name {
        font-size: 10vw;
        width: 100%;
        height: max-content;        
        padding: 2% 200% 2% 200%;
        background-color: rgba(245, 245, 245, 0.404);
        text-align: center;
        z-index: 5;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .character-slide-text {
        background-color: rgba( 245, 245, 245, .5);
        font-size: 5vw;
        width: 100%;
        height: max-content;
        padding: 5% 200% 5% 200%;
        z-index: 5;
        text-align: center;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        margin: auto;
        margin-right: 10px;
        margin-left: 10px;
        visibility: hidden;
    }
    
    
    .character-slides-thumbs {
        width: 100%;
    }
    
    .character-slide-wrapper-thumb {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 9% 0 0 0;
    }
    
    .character-slide-image-thumb {
        width: 50%;
        border-radius: 7% 0% 7% 0%;
        background-color: rgba(191, 186, 219, 0.904);
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        cursor: pointer;
        transition: background-color .25s ease-out 25ms;
    }
    
    .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .character-slide-header-thumb {
        color: whitesmoke;
        text-align: center;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        font-size: 2.5vw;
    }
    
    
    .back-transparent-bar {
        background-color: rgba(68, 68, 68, 0.25);
        width:100%;
        height: 171%;
        position:absolute;
        -webkit-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 60deg) translateY(calc(50vw/-12));
        transform: skew(0, 60deg) translateY(calc(50vw/-12));
        right: 0;
    }
    
    .character-spacer {
        background: rgba(48, 45, 65, 0.3);
        display: flex;
        justify-content: center;
        position: absolute;
        z-index: 9;
        left: 0;
        bottom: 0;
        top: 43.6%;
        padding-top: 2%;
        width: 100%;
        height: 11%;
    }
    
    .swiper-button-next {
        right: 0;
        height: 30%;
    }
    
    .swiper-button-prev {
        left: 0;
        height: 30%;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
        color: whitesmoke !important;
        transition: color .25s ease-out 25ms;
        visibility: hidden;
    }
    
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: rgba(245, 245, 245, 0.685) !important;
    }
    
    .swiper-slide-active .character-slide-image {
            animation: characterAnimation linear 0.5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
            @keyframes characterAnimation {
            0%{
            transform: translateX(100px);
            }
        
            100%{
            transform: translateX(0px);
            }
            }
    
    .swiper-slide-active .character-slide-image-thumb {
        transform: scale(1.3);
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-active .character-slide-header-thumb {
        font-size: 3vw;
    }
    
    .swiper-slide-prev .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2);   
    }
    
    .swiper-slide-prev .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904);
    }
    
    .swiper-slide-next .character-slide-image-thumb {
        background-color: rgba(158, 148, 219, 0.904);
        transform: scale(1.2); 
    }
    
    .swiper-slide-next .character-slide-image-thumb:hover {
        background-color: rgba(131, 115, 219, 0.904); 
    }    
}