/*
===================================
MACBOOK
===================================
*/

@media screen and (min-width: 2561px) {
    /* Home */
    .skyline-home {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .home-video {
        width: 100%;
    }
    
    .skyline-home .home-text {
        z-index: 2;
        position: absolute;
        color: whitesmoke;
        font-size: 4vw;
        bottom: 67%;
        right: 55%;
        font-weight: 475;
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .skyline-home .home-logo {
        z-index: 2;
        position: absolute;
        right: 51%;
        bottom: 34%;
        width: 25%;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .trailer-button {
        position: absolute;
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        bottom: 25%;
        left: 30%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);    
    }
    
    .trailer-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .trailer-overlay{
        z-index: 999;
        position: fixed;
    
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(207, 206, 221, 0.8);   
    }
    
    .trailerVideo{
        position:absolute;
        bottom: 24%;
        right: 25%;
        width: 53.5%;
        height: 48%;
    }
    
    .close-button{
        position: absolute;
        width: 3.5%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        bottom: 70%;
        right: 20%;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);  
    }
    
    .close-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
    }
    }
    
    
      /* About */
    
    .about-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 85%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    @keyframes whiteBarDrop {
        0% {
    
        }
        100% {
    
        }
    }
    
    .about-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 1%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        z-index: 1;
    }
    
    .skyline-about {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 95%;
    }
    
    .about-image {
        width: 105%;
        z-index: 999;
        padding: 11%;
    }
    
    .skyline-text {
        width: 100%;
        padding: 7%;
        margin: 10% auto 10% auto;
    }
    
    .skyline-about-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-about-desc {
        font-size: 1.2vw;
    }
    
    .about-button {
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
    }
    
    .about-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .about-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* Character */
    .character-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 11%;
    }
    
    .skyline-character {
        color: black;
        width: 75%;
        display: flex;
        flex-direction:row-reverse;
        justify-content: stretch;
        align-items: center;
    }
    
    .character-image {
        width: 200%;
        padding: 2% 0% 0% 10%;
    }
    
    .character-text {
        width: 280%;
        padding: 6% 0% 0% 5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .character-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .character-desc {
        font-size: 1.2vw;
    }
    
    .character-button {
        width: 37%;
        font-size: 1.5vw;
        padding: 1.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
    }
    
    .character-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .character-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* News */
    
    .news-wrapper {
        background-color: whitesmoke;
        height: 50%;
        border-bottom-color: whitesmoke;
        border-bottom-style: groove;
        border-bottom-width: .5vw;
    }
    
    .news-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 74%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
    }
    
    .news-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 10%;
        position:absolute;
        left:0;
        z-index: 1;
    }
    
    .skyline-news {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 90%;
    }
    
    .news-image {
        width: 110%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-news-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-news-desc {
        font-size: 1.2vw;
    }
    
    
      /* Credits */
    
    .credit-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 50%;
        padding: 8%;
    }
    
    .credits-header {
        text-align: center;
        font-size: 2.8vw;
        color: whitesmoke;
        animation: cardAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .credit-button {
        width: 17%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
        left: 41.5%;
    }
    
    .credit-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .credit-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .credits-blurb {
        color: whitesmoke;
        font-size: 1.7vw;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
    }
    
      /* Apply */
    
    .apply-wrapper {
        background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 7%;
    }
    
    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .form-head {
        display: flex;
        flex-direction: column; 
        background-color: rgb(22, 6, 80,0.75);
        margin: 2%;
        border-radius: 100px 0 100px 0;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .form-header {
        color: whitesmoke;
        font-size: 2.8vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
        padding: 1%;
    }
    
    .form-text {
        color: whitesmoke;
        font-size: 1.5vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
        text-align: center; 
        padding: 1%;
    }
    
    .form-embed {
        animation: fadeInAnimation ease 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }    
    }


/*
===================================
iMAC
===================================
*/

@media screen and (min-width: 1992px) and (max-width: 2560px) {
    /* Home */
    .skyline-home {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .home-video {
        width: 100%;
    }
    
    .skyline-home .home-text {
        z-index: 2;
        position: absolute;
        color: whitesmoke;
        font-size: 4vw;
        bottom: 67%;
        right: 55%;
        font-weight: 475;
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .skyline-home .home-logo {
        z-index: 2;
        position: absolute;
        right: 51%;
        bottom: 34%;
        width: 25%;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .trailer-button {
        position: absolute;
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        bottom: 25%;
        left: 30%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);    
    }
    
    .trailer-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .trailer-overlay{
        z-index: 999;
        position: fixed;
    
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(207, 206, 221, 0.8);   
    }
    
    .trailerVideo{
        position:absolute;
        bottom: 22%;
        right: 25%;
        width: 53.5%;
        height: 53%;
    }
    
    .close-button{
        position: absolute;
        width: 3.5%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        bottom: 75%;
        right: 20%;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);  
    }
    
    .close-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
    }
    }
    
    
      /* About */
    
    .about-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 90%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    @keyframes whiteBarDrop {
        0% {
    
        }
        100% {
    
        }
    }
    
    .about-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 1%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        z-index: 1;
    }
    
    .skyline-about {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 95%;
    }
    
    .about-image {
        width: 100%;
        z-index: 999;
        padding: 11%;
    }
    
    .skyline-text {
        width: 110%;
        padding: 5.5%;
        margin: 10% auto 10% auto;
    }
    
    .skyline-about-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-about-desc {
        font-size: 1.2vw;
    }
    
    .about-button {
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
    }
    
    .about-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .about-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* Character */
    .character-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 11%;
    }
    
    .skyline-character {
        color: black;
        width: 75%;
        display: flex;
        flex-direction:row-reverse;
        justify-content: stretch;
        align-items: center;
    }
    
    .character-image {
        width: 200%;
        padding: 2% 0% 0% 10%;
    }
    
    .character-text {
        width: 280%;
        padding: 6% 0% 0% 5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .character-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .character-desc {
        font-size: 1.2vw;
    }
    
    .character-button {
        width: 37%;
        font-size: 1.5vw;
        padding: 1.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
    }
    
    .character-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .character-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* News */
    
    .news-wrapper {
        background-color: whitesmoke;
        height: 50%;
        border-bottom-color: whitesmoke;
        border-bottom-style: groove;
        border-bottom-width: .5vw;
    }
    
    .news-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 80%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
    }
    
    .news-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 10%;
        position:absolute;
        left:0;
        z-index: 1;
    }
    
    .skyline-news {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 90%;
    }
    
    .news-image {
        width: 80%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-news-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-news-desc {
        font-size: 1.2vw;
    }
    
    
      /* Credits */
    
    .credit-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 50%;
        padding: 8%;
    }
    
    .credits-header {
        text-align: center;
        font-size: 2.8vw;
        color: whitesmoke;
        animation: cardAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .credit-button {
        width: 17%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
        left: 41.5%;
    }
    
    .credit-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .credit-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .credits-blurb {
        color: whitesmoke;
        font-size: 1.7vw;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
    }
    
      /* Apply */
    
    .apply-wrapper {
        background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 7%;
    }
    
    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .form-head {
        display: flex;
        flex-direction: column; 
        background-color: rgb(22, 6, 80,0.75);
        margin: 2%;
        border-radius: 100px 0 100px 0;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .form-header {
        color: whitesmoke;
        font-size: 2.8vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
        padding: 1%;
    }
    
    .form-text {
        color: whitesmoke;
        font-size: 1.5vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
        text-align: center; 
        padding: 1%;
    }
    
    .form-embed {
        animation: fadeInAnimation ease 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        width: 1000px;
    }    
    }

/*
===================================
DESKTOP LARGE
===================================
*/

@media screen and (min-width: 1600px) and (max-width: 1991px) {
/* Home */
.skyline-home {
    display: flex;
    z-index: 0;
    justify-content: center;
    align-items: center;
    background: rgb(22, 6, 80,0.75);
    position: relative;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.home-video {
    width: 100%;
}

.skyline-home .home-text {
    z-index: 2;
    position: absolute;
    color: whitesmoke;
    font-size: 4vw;
    bottom: 67%;
    right: 55%;
    font-weight: 475;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
    font-family: optima;
}

.skyline-home .home-logo {
    z-index: 2;
    position: absolute;
    right: 51%;
    bottom: 34%;
    width: 25%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.trailer-button {
    position: absolute;
    width: 13%;
    font-size: 1.5vw;
    padding: 0.7%;
    bottom: 25%;
    left: 30%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);    
}

.trailer-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.trailer-overlay{
    z-index: 999;
    position: fixed;

    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(207, 206, 221, 0.8);   
}

.trailerVideo{
    position:absolute;
    bottom: 20%;
    right: 25%;
    width: 53.5%;
    height: 60%;
}

.close-button{
    position: absolute;
    width: 3.5%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    bottom: 75%;
    right: 20%;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);  
}

.close-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
}
}


  /* About */

.about-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.about-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 100%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    animation: whiteBarDrop ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes whiteBarDrop {
    0% {

    }
    100% {

    }
}

.about-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 20%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    z-index: 1;
}

.skyline-about {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 90%;
}

.about-image {
    width: 100%;
    z-index: 999;
    padding: 5%;
}

.skyline-text {
    width: 110%;
    padding: 5.5%;
    margin: 10% auto 10% auto;
}

.skyline-about-header {
    font-size: 2.4vw;
    font-weight: 640;
}

.skyline-about-desc {
    font-size: 1.2vw;
}

.about-button {
    width: 13%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    position: absolute;
}

.about-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.about-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

  /* Character */
.character-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 11%;
}

.skyline-character {
    color: black;
    width: 75%;
    display: flex;
    flex-direction:row-reverse;
    justify-content: stretch;
    align-items: center;
}

.character-image {
    width: 200%;
    padding: 2% 0% 0% 10%;
}

.character-text {
    width: 280%;
    padding: 6% 0% 0% 5%;
    color: whitesmoke;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.character-header {
    font-size: 2.4vw;
    font-weight: 640;
}

.character-desc {
    font-size: 1.2vw;
}

.character-button {
    width: 37%;
    font-size: 1.5vw;
    padding: 1.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
}

.character-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.character-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

  /* News */

.news-wrapper {
    background-color: whitesmoke;
    height: 50%;
    border-bottom-color: whitesmoke;
    border-bottom-style: groove;
    border-bottom-width: .5vw;
}

.news-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 100%;
    position:absolute;
    -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    transform: skew(0, 4deg) translateY(calc(50vw/-12));
    left:0;
}

.news-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 16%;
    position:absolute;
    left:0;
    z-index: 1;
}

.skyline-news {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 90%;
}

.news-image {
    width: 80%;
    z-index: 999;
    padding: 5%;
}

.skyline-news-header {
    font-size: 2.4vw;
    font-weight: 640;
}

.skyline-news-desc {
    font-size: 1.2vw;
}


  /* Credits */

.credit-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 50%;
    padding: 8%;
}

.credits-header {
    text-align: center;
    font-size: 2.8vw;
    color: whitesmoke;
    animation: cardAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.credit-button {
    width: 17%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    position: absolute;
    left: 41.5%;
}

.credit-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.credit-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.credits-blurb {
    color: whitesmoke;
    font-size: 1.7vw;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
}

  /* Apply */

.apply-wrapper {
    background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 7%;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.form-head {
    display: flex;
    flex-direction: column; 
    background-color: rgb(22, 6, 80,0.75);
    margin: 2%;
    border-radius: 100px 0 100px 0;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.form-header {
    color: whitesmoke;
    font-size: 2.8vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
    padding: 1%;
}

.form-text {
    color: whitesmoke;
    font-size: 1.5vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
    text-align: center; 
    padding: 1%;
}

.form-embed {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}    
}

/*
===================================
DESKTOP
===================================
*/

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .skyline-home {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .home-video {
        width: 100%;
    }
    
    .skyline-home .home-text {
        z-index: 2;
        position: absolute;
        color: whitesmoke;
        font-size: 4vw;
        bottom: 67%;
        right: 55%;
        font-weight: 475;
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .skyline-home .home-logo {
        z-index: 2;
        position: absolute;
        right: 57%;
        bottom: 35%;
        width: 25%;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .trailer-button {
        position: absolute;
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        bottom: 25%;
        left: 25%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);    
    }
    
    .trailer-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .trailer-overlay{
        z-index: 999;
        position: fixed;
    
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(207, 206, 221, 0.8);   
    }
    
    .trailerVideo{
        position:absolute;
        bottom: 20%;
        right: 25%;
        width: 53.5%;
        height: 56%;
    }
    
    .close-button{
        position: absolute;
        width: 3.5%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        bottom: 75%;
        right: 20%;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);  
    }
    
    .close-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
    }
    }
    
    
      /* About */
    
    .about-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 100%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    @keyframes whiteBarDrop {
        0% {
    
        }
        100% {
    
        }
    }
    
    .about-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 20%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        z-index: 1;
    }
    
    .skyline-about {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 90%;
    }
    
    .about-image {
        width: 100%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-text {
        width: 110%;
        padding: 5.5%;
        margin: 10% auto 10% auto;
    }
    
    .skyline-about-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-about-desc {
        font-size: 1.2vw;
    }
    
    .about-button {
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
    }
    
    .about-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .about-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* Character */
    .character-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 11%;
    }
    
    .skyline-character {
        color: black;
        width: 75%;
        display: flex;
        flex-direction:row-reverse;
        justify-content: stretch;
        align-items: center;
    }
    
    .character-image {
        width: 200%;
        padding: 2% 0% 0% 10%;
    }
    
    .character-text {
        width: 280%;
        padding: 6% 0% 0% 5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .character-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .character-desc {
        font-size: 1.2vw;
    }
    
    .character-button {
        width: 37%;
        font-size: 1.5vw;
        padding: 1.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
    }
    
    .character-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .character-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* News */
    
    .news-wrapper {
        background-color: whitesmoke;
        height: 50%;
        border-bottom-color: whitesmoke;
        border-bottom-style: groove;
        border-bottom-width: .5vw;
    }
    
    .news-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 100%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
    }
    
    .news-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 16%;
        position:absolute;
        left:0;
        z-index: 1;
    }
    
    .skyline-news {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 90%;
    }
    
    .news-image {
        width: 80%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-news-header {
        font-size: 2.4vw;
        font-weight: 640;
    }
    
    .skyline-news-desc {
        font-size: 1.2vw;
    }
    
    
      /* Credits */
    
    .credit-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 50%;
        padding: 8%;
    }
    
    .credits-header {
        text-align: center;
        font-size: 2.8vw;
        color: whitesmoke;
        animation: cardAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .credit-button {
        width: 17%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
        left: 41.5%;
    }
    
    .credit-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .credit-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .credits-blurb {
        color: whitesmoke;
        font-size: 1.7vw;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
    }
    
      /* Apply */
    
    .apply-wrapper {
        background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 7%;
    }
    
    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .form-head {
        display: flex;
        flex-direction: column; 
        background-color: rgb(22, 6, 80,0.75);
        margin: 2%;
        border-radius: 100px 0 100px 0;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .form-header {
        color: whitesmoke;
        font-size: 2.8vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
        padding: 1%;
    }
    
    .form-text {
        color: whitesmoke;
        font-size: 1.5vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
        text-align: center; 
        padding: 1%;
    }
    
    .form-embed {
        animation: fadeInAnimation ease 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        width: 700px; 
    }      
}

/*
===================================
WEB LARGE
===================================
*/

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .skyline-home {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .home-video {
        width: 100%;
    }
    
    .skyline-home .home-text {
        z-index: 2;
        position: absolute;
        color: whitesmoke;
        font-size: 4vw;
        bottom: 66%;
        right: 55%;
        font-weight: 475;
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .skyline-home .home-logo {
        z-index: 2;
        position: absolute;
        right: 56%;
        bottom: 33%;
        width: 25%;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .trailer-button {
        position: absolute;
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        bottom: 25%;
        left: 24%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 2px solid rgba(191, 186, 219, 0.904);    
    }
    
    .trailer-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .trailer-overlay{
        z-index: 999;
        position: fixed;
    
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(207, 206, 221, 0.8);   
    }
    
    .trailerVideo{
        position:absolute;
        bottom: 20%;
        right: 25%;
        width: 53.5%;
        height: 56%;
    }
    
    .close-button{
        position: absolute;
        width: 3.5%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        bottom: 75%;
        right: 20%;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 2px solid rgba(191, 186, 219, 0.904);  
    }
    
    .close-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
    }
    }
    
    
      /* About */
    
    .about-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 85%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    @keyframes whiteBarDrop {
        0% {
    
        }
        100% {
    
        }
    }
    
    .about-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 5%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        z-index: 1;
    }
    
    .skyline-about {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 95%;
    }
    
    .about-image {
        width: 100%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-text {
        width: 110%;
        padding: 5.5%;
        margin: 10% auto 10% auto;
    }
    
    .skyline-about-header {
        font-size: 3vw;
        font-weight: 640;
    }
    
    .skyline-about-desc {
        font-size: 1.6vw;
    }
    
    .about-button {
        width: 13%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
    }
    
    .about-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .about-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* Character */
    .character-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 11%;
    }
    
    .skyline-character {
        color: black;
        width: 75%;
        display: flex;
        flex-direction:row-reverse;
        justify-content: stretch;
        align-items: center;
    }
    
    .character-image {
        width: 200%;
        padding: 2% 0% 0% 10%;
    }
    
    .character-text {
        width: 280%;
        padding: 6% 0% 0% 5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .character-header {
        font-size: 3vw;
        font-weight: 640;
    }
    
    .character-desc {
        font-size: 1.6vw;
    }
    
    .character-button {
        width: 37%;
        font-size: 1.5vw;
        padding: 1.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
    }
    
    .character-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .character-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
      /* News */
    
    .news-wrapper {
        background-color: whitesmoke;
        height: 50%;
        border-bottom-color: whitesmoke;
        border-bottom-style: groove;
        border-bottom-width: .5vw;
    }
    
    .news-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 80%;
        position:absolute;
        -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
        transform: skew(0, 4deg) translateY(calc(50vw/-12));
        left:0;
    }
    
    .news-white-bar-bottom {
        background-color: whitesmoke;
        width:100%;
        height: 10%;
        position:absolute;
        left:0;
        z-index: 1;
    }
    
    .skyline-news {
        display: flex;
        flex-direction: row;
        color: black;
        justify-content: space-around;
        width: 95%;
    }
    
    .news-image {
        width: 80%;
        z-index: 999;
        padding: 5%;
    }
    
    .skyline-news-header {
        font-size: 3vw;
        font-weight: 640;
    }
    
    .skyline-news-desc {
        font-size: 1.6vw;
    }
    
    
      /* Credits */
    
    .credit-wrapper {
        background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
        height: 50%;
        padding: 8%;
    }
    
    .credits-header {
        text-align: center;
        font-size: 2.8vw;
        color: whitesmoke;
        animation: cardAnimation ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    }
    
    .credit-button {
        width: 17%;
        font-size: 1.5vw;
        padding: 0.7%;
        border: none;
        color: whitesmoke;
        background-color: rgb(145, 140, 216);
        border-radius: 500px;
        font-weight: 550;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
        border: 3px solid rgba(191, 186, 219, 0.904);
        position: absolute;
        left: 41.5%;
    }
    
    .credit-link {
        color: whitesmoke; 
        text-decoration: none; 
    }
    
    .credit-button:hover{
        background-color: rgba(173, 165, 223);
        font-size: 1.55vw;
        cursor: pointer;
    }
    
    .credits-blurb {
        color: whitesmoke;
        font-size: 1.7vw;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
    }
    
      /* Apply */
    
    .apply-wrapper {
        background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
        height: 60%;
        padding: 7%;
    }
    
    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .form-head {
        display: flex;
        flex-direction: column; 
        background-color: rgb(22, 6, 80,0.75);
        margin: 2%;
        border-radius: 100px 0 100px 0;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .form-header {
        color: whitesmoke;
        font-size: 2.8vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
        text-align: center;
        padding: 1%;
    }
    
    .form-text {
        color: whitesmoke;
        font-size: 1.5vw;
        font-weight: 700;
        text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
        text-align: center; 
        padding: 1%;
    }
    
    .form-embed {
        animation: fadeInAnimation ease 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        width: 650px; 
    }      
}


/*
===================================
WEB SMALLER
===================================
*/

@media screen and (min-width: 768px) and (max-width: 1023px) {

/* Home */
.skyline-home {
    display: flex;
    z-index: 0;
    justify-content: center;
    align-items: center;
    background: rgb(22, 6, 80,0.75);
    position: relative;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.home-video {
    width: 100%;
}

.skyline-home .home-text {
    z-index: 2;
    position: absolute;
    color: whitesmoke;
    font-size: 4vw;
    bottom: 67%;
    right: 55%;
    font-weight: 475;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
    font-family: optima;
}

.skyline-home .home-logo {
    z-index: 2;
    position: absolute;
    right: 56%;
    bottom: 34%;
    width: 25%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.trailer-button {
    position: absolute;
    width: 13%;
    font-size: 1.5vw;
    padding: 0.7%;
    bottom: 25%;
    left: 24%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);    
}

.trailer-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.trailer-overlay{
    z-index: 999;
    position: fixed;

    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(207, 206, 221, 0.8);   
}

.trailerVideo{
    position:absolute;
    bottom: 20%;
    right: 25%;
    width: 53.5%;
    height: 56%;
}

.close-button{
    position: absolute;
    width: 3.5%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    bottom: 75%;
    right: 20%;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);  
}

.close-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
}
}


  /* About */

.about-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.about-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 60%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    animation: whiteBarDrop ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes whiteBarDrop {
    0% {

    }
    100% {

    }
}

.about-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 10%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    z-index: 1;
}

.skyline-about {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 95%;
}

.about-image {
    width: 100%;
    z-index: 999;
    padding: 5%;
}

.skyline-text {
    width: 110%;
    padding: 5.5%;
    margin: 10% auto 10% auto;
}

.skyline-about-header {
    font-size: 3vw;
    font-weight: 640;
}

.skyline-about-desc {
    font-size: 1.6vw;
}

.about-button {
    width: 15%;
    font-size: 2vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
    position: absolute;
}

.about-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.about-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 2.05vw;
    cursor: pointer;
}

  /* Character */
.character-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 10%;
}

.skyline-character {
    color: black;
    width: 75%;
    display: flex;
    flex-direction:row-reverse;
    justify-content: stretch;
    align-items: center;
}

.character-image {
    width: 180%;
    padding: 2% 0% 0% 10%;
}

.character-text {
    width: 280%;
    padding: 6% 0% 0% 5%;
    color: whitesmoke;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.character-header {
    font-size: 3vw;
    font-weight: 640;
}

.character-desc {
    font-size: 1.6vw;
}

.character-button {
    width: 40%;
    font-size: 2vw;
    padding: 1.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
}

.character-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.character-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 2.05vw;
    cursor: pointer;
}

  /* News */

.news-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.news-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 60%;
    position:absolute;
    -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    transform: skew(0, 4deg) translateY(calc(50vw/-12));
    left:0;
}

.news-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 10%;
    position:absolute;
    left:0;
    z-index: 1;
}

.skyline-news {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 95%;
}

.news-image {
    width: 80%;
    z-index: 999;
    padding: 5%;
}

.skyline-news-header {
    font-size: 3vw;
    font-weight: 640;
}

.skyline-news-desc {
    font-size: 1.6vw;
}


  /* Credits */

.credit-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 50%;
    padding: 10%;
}

.credits-header {
    text-align: center;
    font-size: 2.8vw;
    color: whitesmoke;
    animation: cardAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.credit-button {
    width: 17%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    position: absolute;
    left: 41.5%;
}

.credit-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.credit-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.credits-blurb {
    color: whitesmoke;
    font-size: 1.7vw;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
}

  /* Apply */

.apply-wrapper {
    background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 7%;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.form-head {
    display: flex;
    flex-direction: column; 
    background-color: rgb(22, 6, 80,0.75);
    margin: 2%;
    border-radius: 100px 0 100px 0;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.form-header {
    color: whitesmoke;
    font-size: 5vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
    padding: 1%;
}

.form-text {
    color: whitesmoke;
    font-size: 1.8vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
    text-align: center; 
    padding: 1%;
}

.form-embed {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 600px;
}

}

/*
===================================
TABLET
===================================
*/

@media screen and (min-width: 480px) and (max-width: 767px) {
/* Home */
.skyline-home {
    display: flex;
    z-index: 0;
    justify-content: center;
    align-items: center;
    background: rgb(22, 6, 80,0.75);
    position: relative;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.home-video {
    width: 100%;
}

.skyline-home .home-text {
    z-index: 2;
    position: absolute;
    color: whitesmoke;
    font-size: 4vw;
    bottom: 67%;
    right: 55%;
    font-weight: 475;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
    font-family: optima;
}

.skyline-home .home-logo {
    z-index: 2;
    position: absolute;
    right: 56%;
    bottom: 34%;
    width: 25%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.trailer-button {
    position: absolute;
    width: 13%;
    font-size: 1.5vw;
    padding: 0.7%;
    bottom: 25%;
    left: 24%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);    
}

.trailer-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.trailer-overlay{
    z-index: 999;
    position: fixed;

    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(207, 206, 221, 0.8);   
}

.trailerVideo{
    position:absolute;
    bottom: 22%;
    right: 25%;
    width: 53.5%;
    height: 50%;
}

.close-button{
    position: absolute;
    width: 3.5%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    bottom: 67%;
    right: 20%;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);  
}

.close-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
}
}


  /* About */

.about-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.about-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 60%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    animation: whiteBarDrop ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes whiteBarDrop {
    0% {

    }
    100% {

    }
}

.about-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 10%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    z-index: 1;
}

.skyline-about {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 95%;
}

.about-image {
    width: 100%;
    z-index: 999;
    padding: 5%;
}

.skyline-text {
    width: 110%;
    padding: 5.5%;
    margin: 10% auto 10% auto;
}

.skyline-about-header {
    font-size: 3vw;
    font-weight: 640;
}

.skyline-about-desc {
    font-size: 1.6vw;
}

.about-button {
    width: 15%;
    font-size: 2vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
    position: absolute;
}

.about-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.about-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 2.05vw;
    cursor: pointer;
}

  /* Character */
.character-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 10%;
}

.skyline-character {
    color: black;
    width: 75%;
    display: flex;
    flex-direction:row-reverse;
    justify-content: stretch;
    align-items: center;
}

.character-image {
    width: 175%;
    padding: 2% 0% 0% 10%;
}

.character-text {
    width: 280%;
    padding: 6% 0% 0% 5%;
    color: whitesmoke;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.character-header {
    font-size: 3vw;
    font-weight: 640;
}

.character-desc {
    font-size: 1.6vw;
}

.character-button {
    width: 40%;
    font-size: 2vw;
    padding: 1.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
}

.character-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.character-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 2.05vw;
    cursor: pointer;
}

  /* News */

.news-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.news-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 60%;
    position:absolute;
    -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    transform: skew(0, 4deg) translateY(calc(50vw/-12));
    left:0;
}

.news-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 7%;
    position:absolute;
    left:0;
    z-index: 1;
}

.skyline-news {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-around;
    width: 95%;
}

.news-image {
    width: 80%;
    z-index: 999;
    padding: 5%;
}

.skyline-news-header {
    font-size: 3vw;
    font-weight: 640;
}

.skyline-news-desc {
    font-size: 1.6vw;
}


  /* Credits */

.credit-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 50%;
    padding: 10%;
}

.credits-header {
    text-align: center;
    font-size: 2.8vw;
    color: whitesmoke;
    animation: cardAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.credit-button {
    width: 17%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 3px solid rgba(191, 186, 219, 0.904);
    position: absolute;
    left: 41.5%;
}

.credit-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.credit-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.credits-blurb {
    color: whitesmoke;
    font-size: 1.7vw;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
}

  /* Apply */

.apply-wrapper {
    background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 7%;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.form-head {
    display: flex;
    flex-direction: column; 
    background-color: rgb(22, 6, 80,0.75);
    margin: 2%;
    border-radius: 100px 0 100px 0;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.form-header {
    color: whitesmoke;
    font-size: 5vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
    padding: 1%;
}

.form-text {
    color: whitesmoke;
    font-size: 1.7vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
    text-align: center; 
    padding: 1%;
}

.form-embed {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 550px;
}
}

/*
===================================
MOBILE
===================================
*/

@media screen and (max-width: 479px) {

/* Home */
.skyline-home {
    display: flex;
    z-index: 0;
    justify-content: center;
    align-items: center;
    background: rgb(22, 6, 80,0.75);
    position: relative;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.home-video {
    width: 100%;
}

.skyline-home .home-text {
    z-index: 2;
    position: absolute;
    color: whitesmoke;
    font-size: 4vw;
    bottom: 60%;
    right: 55%;
    font-weight: 475;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
    font-family: optima;
}

.skyline-home .home-logo {
    z-index: 2;
    position: absolute;
    right: 56%;
    bottom: 25%;
    width: 25%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.trailer-button {
    position: absolute;
    visibility: hidden;
    width: 13%;
    font-size: 1.5vw;
    padding: 0.7%;
    bottom: 25%;
    left: 24%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);    
}

.trailer-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

.trailer-overlay{
    z-index: 999;
    position: fixed;

    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(207, 206, 221, 0.8);   
}

.trailerVideo{
    position:absolute;
    bottom: 22%;
    right: 25%;
    width: 53.5%;
    height: 50%;
}

.close-button{
    position: absolute;
    width: 3.5%;
    font-size: 1.5vw;
    padding: 0.7%;
    border: none;
    bottom: 67%;
    right: 20%;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);  
}

.close-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.55vw;
    cursor: pointer;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
}
}


  /* About */

.about-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.about-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 8%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    animation: whiteBarDrop ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes whiteBarDrop {
    0% {

    }
    100% {

    }
}

.about-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 7%;
    position:absolute;
    -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
    transform: skew(0, -4deg) translateY(calc(50vw/-12));
    left:0;
    z-index: 1;
}

.skyline-about {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-between;
    width: 100%;
}

.about-image {
    width: 80%;
    z-index: 999;
    padding: 4%;
}

.skyline-text {
    width: 200%;
    padding: 3%;
    margin: 5% auto 10% auto;
}

.skyline-about-header {
    font-size: 4vw;
    font-weight: 640;
}

.skyline-about-desc {
    font-size: 2.5vw;
}

.about-button {
    width: 21%;
    font-size: 2.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
    position: absolute;
}

.about-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.about-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.81vw;
    cursor: pointer;
}

  /* Character */
.character-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 50%;
    padding: 5%;
}

.skyline-character {
    color: black;
    width: 82%;
    display: flex;
    flex-direction:row-reverse;
    justify-content: space-between;
    align-items: center;
}

.character-image {
    width: 120%;
    padding: 20% 0% 0% 20%;
}

.character-text {
    width: 280%;
    padding: 6% 0% 0% 5%;
    color: whitesmoke;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
}

.character-header {
    font-size: 4vw;
    font-weight: 640;
}

.character-desc {
    font-size: 2.5vw;
}

.character-button {
    width: 65%;
    font-size: 2.5vw;
    padding: 1.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
}

.character-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.character-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.81vw;
    cursor: pointer;
}

  /* News */

.news-wrapper {
    background-color: whitesmoke;
    height: 50%;
}

.news-white-bar-top {
    background-color: whitesmoke;
    width:100%;
    height: 7%;
    position:absolute;
    -webkit-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, 4deg) translateY(calc(50vw/-12));
    transform: skew(0, 4deg) translateY(calc(50vw/-12));
    left:0;
}

.news-white-bar-bottom {
    background-color: whitesmoke;
    width:100%;
    height: 5%;
    position:absolute;
    left:0;
    z-index: 1;
}

.skyline-news {
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: space-between;
    width: 100%;
}

.news-image {
    width: 80%;
    z-index: 999;
    padding: 4%;
}

.skyline-news-header {
    font-size: 4vw;
    font-weight: 640;
}

.skyline-news-desc {
    font-size: 2.5vw;
}


  /* Credits */

.credit-wrapper {
    background: linear-gradient(rgba(22, 6, 80, 0.9), rgba(84, 53, 196, 0.9), rgba(145, 140, 216, 0.8)), url(../images/stars2.gif) repeat;
    height: 50%;
    padding: 8.5%;
}

.credits-header {
    text-align: center;
    font-size: 4vw;
    color: whitesmoke;
    animation: cardAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
    padding: 2%;
}

.credit-button {
    width: 25%;
    font-size: 2.5vw;
    padding: 0.7%;
    border: none;
    color: whitesmoke;
    background-color: rgb(145, 140, 216);
    border-radius: 500px;
    font-weight: 550;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    transition: color, font-size .25s ease-out 25ms, .25s ease-out 25ms;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    border: 2px solid rgba(191, 186, 219, 0.904);
    position: absolute;
    left: 38%;
}

.credit-link {
    color: whitesmoke; 
    text-decoration: none; 
}

.credit-button:hover{
    background-color: rgba(173, 165, 223);
    font-size: 1.71vw;
    cursor: pointer;
}

.credits-blurb {
    color: whitesmoke;
    font-size: 3vw;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
}

  /* Apply */

.apply-wrapper {
    background: linear-gradient(rgba(145, 140, 216, 0.8), rgba(207, 206, 221, 0.8)), url(../images/stars2.gif) repeat;
    height: 60%;
    padding: 13% 0% 0% 0%;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.form-head {
    display: flex;
    flex-direction: column; 
    background-color: rgb(22, 6, 80,0.75);
    margin: 2%;
    border-radius: 100px 0 100px 0;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
}

.form-header {
    color: whitesmoke;
    font-size: 7vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%);
    text-align: center;
    padding: 1%;
}

.form-text {
    color: whitesmoke;
    font-size: 3.5vw;
    font-weight: 700;
    text-shadow: 0 3px 7px rgb(0 0 0 / 35%); 
    text-align: center; 
    padding: 2.5%;
}

.form-embed {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 370px;
}
}