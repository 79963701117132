/*
===================================
MACBOOK
===================================
*/

@media screen and (min-width: 2561px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 35%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 17%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }   
}

/*
===================================
iMAC
===================================
*/

@media screen and (min-width: 1992px) and (max-width: 2560px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 35%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 17%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }      
}

/*
===================================
DESKTOP LARGE
===================================
*/

@media screen and (min-width: 1600px) and (max-width: 1991px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 35%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 17%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 12%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }    
}

/*
===================================
DESKTOP
===================================
*/

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 17%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }        
}

/*
===================================
WEB LARGE
===================================
*/

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 30%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 17%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }         
}


/*
===================================
WEB SMALLER
===================================
*/

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 25%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 15%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 2vw;
    }
    
    .about-page-content-section p {
        font-size: 1.6vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 12.4%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 8%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 9%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 7%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }    
}


/*
===================================
TABLET
===================================
*/

@media screen and (min-width: 480px) and (max-width: 767px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 25%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 10%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 3vw;
    }
    
    .about-page-content-section p {
        font-size: 1.8vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/skyline-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 11%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/story-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 7%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/dynamic-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 8%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/world-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 6%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/creatures-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 8.5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/characters-smaller.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 7.5%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }
}


/*
===================================
MOBILE
===================================
*/

@media screen and (max-width: 479px) {
    .about-page-wrapper {
        background-color: whitesmoke;
        height: 50%;
    }
    
    .about-page-header {
        display: flex;
        z-index: 0;
        justify-content: center;
        align-items: center;
        background: rgb(22, 6, 80,0.75);
        position: relative;
        box-shadow: 0 3px 7px 0 rgb(0 0 0 / 35%);
    }
    
    .about-page-header-img {
        width: 100%;
    }
    
    .about-page-header-text {
        z-index: 2;
        text-align: center;
        position: absolute;
        color: whitesmoke;
        font-size: 8vw;
        bottom: 20%;
        right: 37%;
        font-weight: 475;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        text-shadow: 0 3px 7px rgb(0 0 0 / 100%);
        font-family: optima;
    }
    
    .about-page-white-bar-top {
        background-color: whitesmoke;
        width:100%;
        height: 5.5%;
        position:absolute;
        -webkit-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        -ms-transform: skew(0, -4deg) translateY(calc(50vw/-12));
        transform: skew(0, -4deg) translateY(calc(50vw/-12));
        left:0;
        animation: whiteBarDrop ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    
    .about-page-content-section {
        height: 100%;
        background-color: whitesmoke;
        z-index: 999;
    }
    
    .about-page-content-section h2 {
        font-size: 4vw;
    }
    
    .about-page-content-section p {
        font-size: 2.5vw;
    }
    
    .about-page-content-section img {
        width: 50%;
    }
    
    .about-page-content-title {
        background-image: url('../images/mobile/skyline-mobile.png');
        background-repeat: no-repeat;
        padding: 10%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-story {
        background-image: url('../images/mobile/story-mobile.png');
        background-repeat: no-repeat;
        padding: 4%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-dynamic {
        background-image: url('../images/mobile/dynamic-mobile.png');
        background-repeat: no-repeat;
        padding: 7%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;     
    }
    
    .about-page-content-world {
        background-image: url('../images/mobile/world-mobile.png');
        background-repeat: no-repeat;
        padding: 1%;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;  
        color: whitesmoke;  
    }
    
    .about-page-content-creatures {
        background-image: url('../images/mobile/creatures-mobile.png');
        background-repeat: no-repeat;
        padding: 4%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;
    }
    
    .about-page-content-creatures-para, .about-page-content-story-para {
        padding: 0% 50% 0% 0%;
    }
    
    .about-page-content-characters {
        background-image: url('../images/mobile/characters-mobile.png');
        background-repeat: no-repeat;
        padding: 3%;
        color: whitesmoke;
        text-shadow: 0 3px 7px rgb(0 0 0 / 60%);
        text-align: left;    
    }
    
    .about-page-content-title-header {
        padding: 15% 0% 0% 50%;   
    }
    
    .about-page-content-title-para {
        padding: 0% 0% 0% 50%;
    }
    
    .about-page-content-characters-header, .about-page-content-world-header, .about-page-content-dynamic-header {
        padding: 0% 0% 0% 50%;    
    }
    
    .about-page-content-characters-para, .about-page-content-world-para, .about-page-content-dynamic-para {
        padding: 0% 0% 0% 50%;
    }    
}